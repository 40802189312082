import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Partners from '../components/sections/partners'
import ClickWall from '../components/sections/click-wall'

const AutoOffers = ({ location }) => (
  <Layout phone="888-996-1365" location={location}>
    <SEO
      title="Auto Insurance Offers"
      keywords={[`auto`, `insurance`, `plans`, `affordable`]}
    />
    <Header phone="888-996-1365" />
    <main id="main">
      <ClickWall hideGen />
      <Partners />
    </main>
  </Layout>
)

export default AutoOffers
